<template>
  <div class="evaluate-card">
    <div v-if="evaluateInfo.total * 1 > 0">
      <div class="card-title">
        <span>宝贝评价（{{ evaluateInfo.total }}）</span>
        <div class="look-all" @click="lookAll">
          <span>查看全部</span>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="list" v-for="item in evaluateInfo.items" :key="item.id">
        <div class="user-info">
          <div class="head-img-box">
            <img :src="item.head_img" />
          </div>
          <div class="user-rate">
            <div>{{ item.nickname }}</div>
            <van-rate
              v-model="item.grade"
              :readonly="true"
              size="13"
              gutter="1"
            />
          </div>
        </div>
        <p class="evaluate-content" @click="lookAll">{{ item.content }}</p>
        <div class="evaluate-img-box">
          <div
            class="evaluate-img"
            v-for="(img, imgIndex) in item.goods_imgs"
            :key="imgIndex"
          >
            <img :src="img" @click="previewImg(item.goods_imgs, imgIndex)" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-title" v-else>
      <span>暂无评价</span>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "goods-evaluate",
  props: {
    evaluateInfo: {
      default: [],
    },
    goodsData: {
      default: "",
    },
  },
  data() {
    return {
      goodsId: "",
    };
  },
  mounted() {
    this.goodsId = this.goodsData.goodsId;
  },
  methods: {
    previewImg(imgArr, index) {
      ImagePreview({
        images: imgArr,
        startPosition: index,
      });
    },
    lookAll() {
      this.$router.push({
        name: "evaluate-page",
        query: { goodsId: this.goodsId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.evaluate-card {
  margin: 0 13px 12px 13px;
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 16px;
}
.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  .look-all {
    display: flex;
    align-items: center;
    color: #ed301d;
    font-size: 14px;
    font-weight: normal;
    span {
      margin-right: 4px;
    }
  }
}
.list {
  margin-top: 20px;
  .user-info {
    display: flex;
    align-items: center;
    .head-img-box {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .user-rate {
    font-size: 16px;
  }
  .evaluate-img-box {
    display: flex;
    // justify-content: space-between;

    .evaluate-img {
      width: 116px;
      height: 116px;
      margin-right: 7px;
      flex-shrink: 0;
      &:last-child {
        margin: 0;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .evaluate-content {
    font-size: 14px;
    word-wrap: break-word;
  }
}
</style>