<template>
  <div class="info-card">
    <div v-if="goodsClass.goodsType !== 3">
      <div class="price" v-if="goodsClass.goodsType !== 5">
        <Price :price="info.price" integerStyle="23" decimalStyle="16"></Price>
        <div
          class="discounts-behind"
          v-if="deduction > 0 && goodsClass.goodsType !== 2"
        >
          券后￥{{ discountMoney }}
        </div>
        <div class="sell-number">月销{{ info.sales }}+件</div>
      </div>
      <div class="discounts" v-if="deduction > 0 && goodsClass.goodsType !== 2">
        <span class="discounts-label">{{
          fullMoney > 0
            ? `满${fullMoney}元减${deduction}元`
            : `优惠券可抵${deduction}元`
        }}</span>
        <div class="get-discounts" @click="openDiscounts">
          <span class="text">领券</span>
          <div class="arrow-icon-box">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="consumption-gold" v-if="info.is_expense === 1 && false">
        消费金商品
      </div>
      <div class="goods-info">
        <div class="goods-name">{{ info.good_name }}</div>
        <div class="share" @click="commonShare">
          <img src="@/assets/common/icon-share@2x.png" class="share-img" />
          <span class="share-text">分享</span>
        </div>
      </div>
    </div>
    <!-- 进货商品和公益豆商品 -->
    <div class="stock-beans-goods" v-else>
      <div class="good-info">
        <div class="present-price" v-if="goodsClass.goodsType === 2">
          <span>￥</span>
          <span class="text-bold">{{ toInteger(info.price) || 0 }}</span>
          <span>{{ twoFloating(info.price) || 0 }}</span>
        </div>
        <div class="present-price-beans" v-else>
          <span>{{ info.price }}公益豆</span>
        </div>
        <div class="quantity">
          <span class="sales-volume" v-if="goodsClass.goodsType === 2">
            月销{{ info.sales }}
          </span>
          <span class="sales-volume" v-else>已兑换{{ info.sales }}件</span>
        </div>
      </div>
      <div class="good-title">{{ info.good_name }}</div>
    </div>
    <Share
      v-if="
        goodsClass.goodsType === 1 ||
        goodsClass.goodsType === 4 ||
        goodsClass.goodsType === 5
      "
      :isShare="isShare"
      :goodsClass="goodsClass"
      @closeShare="closeShare"
    ></Share>
    <GetDiscounts
      v-if="loadCoupon"
      ref="discount"
      :goodsId="info.id"
      :loading="loading"
      :goodsClass="goodsClass"
      @getDiscount="updatePrice"
      @updatePrice="updatePrice"
    ></GetDiscounts>
  </div>
</template>

<script>
import Share from "@/components/share/share";
import GetDiscounts from "../../../components/get-discounts/get-discounts";
import tools from "@/common/js/tools";
import { keepTwoDecimalFull } from "@/common/js/utils.js";
export default {
  name: "goods-info",
  components: {
    Share,
    GetDiscounts,
  },
  props: {
    goodsClass: {
      type: Object,
    },
  },
  watch: {},
  data() {
    return {
      info: {},
      isShare: false,
      price: "",
      discountMoney: 0,
      deduction: 0,
      fullType: 0,
      fullMoney: 0,
      noAlready: [],
      already: [],
      goodsId: "",
      page: 1,
      loading: false,
      loadCoupon: false,
    };
  },

  mounted() {},
  methods: {
    setGoodsInfo(goodsInfo) {
      this.info = goodsInfo;
      this.loadCoupon = true;
    },
    openDiscounts() {
      this.$refs.discount.openPopup();
    },
    // getDiscountItem(item) {
    //   this.fullMoney = item.full_money;
    //   this.fullType = item.full_type;
    //   this.deduction = item.money;
    //   this.computePrice(item.money);
    // },
    updatePrice(data) {
      console.log(data);
      this.fullType = data.full_type;
      this.fullMoney = data.full_money;
      this.deduction = data.money;
      this.computePrice(data.money);
    },
    computePrice(price) {
      //  计算券后价格
      // console.log("this.fullType----------------", this.fullType);
      // console.log("this.info.price--------------", this.info.price);
      // console.log("price------------------------", price);
      if (this.fullType === 1 && this.fullMoney > this.info.price) {
        this.discountMoney =
          this.info.price - (this.info.price / this.fullMoney) * price;
        this.discountMoney = keepTwoDecimalFull(this.discountMoney, 2);
      } else {
        this.discountMoney = this.info.price - price;
        this.discountMoney = keepTwoDecimalFull(this.discountMoney, 2);
        if (this.discountMoney <= 0) {
          this.discountMoney = 0;
        }
      }
      // console.log("this.discountMoney-----------", this.discountMoney);
    },
    commonShare() {
      // 普通分享
      this.isShare = true;
    },
    seckillShare() {
      // 秒杀分享
      this.isShare = true;
    },
    closeShare() {
      // 关闭分享
      this.isShare = false;
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
    },
  },
};
</script>

<style lang="less" scoped>
// card
.info-card {
  padding: 16px 12px;
  margin: 12px;
  background-color: #fff;
  border-radius: 16px;
  .price {
    display: flex;
    align-items: center;
    position: relative;
    .discounts-behind {
      padding: 5px 6px;
      font-size: 16px;
      background: rgb(237, 48, 29);
      border-radius: 16px;
      margin-left: 10px;
      color: #fff;
    }
    .sell-number {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      color: #6f6f6f;
      font-size: 16px;
    }
  }
  .discounts {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 31px;
    background-color: #fff4f4;
    margin-top: 13px;
    padding-left: 6px;
    .discounts-label {
      color: #ed301d;
      font-size: 16px;
    }
    .get-discounts {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      width: 72px;
      height: 31px;
      padding-left: 10px;
      background: linear-gradient(270deg, #ed301d 0%, #f55a4b 100%);
      border-radius: 65px 0px 0px 65px;
      box-sizing: border-box;
      color: #fff;
      .text {
        font-size: 16px;
        margin-bottom: 2px;
      }
      .arrow-icon-box {
        position: absolute;
        top: 50%;
        right: 9px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        font-size: 12px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }
  .consumption-gold {
    display: table;
    font-size: 16px;
    margin-top: 13px;
    border: 1px solid #ed301d;
    border-radius: 4px;
    padding: 2px 6px;
    color: #ed301d;
  }
  .goods-info {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    .goods-name {
      width: 314px;
      font-size: 17px;
      font-weight: 500;
    }
    .share {
      display: flex;
      flex-direction: column;
      align-items: center;
      .share-img {
        width: 25px;
        height: 25px;
      }
      .share-text {
        font-size: 16px;
        color: #6f6f6f;
      }
    }
  }
  // =---------------------------------
  .present-price {
    font-size: 13px;
    font-weight: 500;
    color: #ed301d;
    .text-bold {
      font-size: 21px;
    }
  }
  .present-price-beans {
    font-size: 20px;
    color: #ed301d;
    font-weight: 500;
  }
  .sales-volume {
    color: #6f6f6f;
    font-size: 16px;
  }
  .good-title {
    padding-top: 16px;
    font-size: 17px;
    font-weight: 500;
  }
  .share {
    display: flex;
    align-items: center;
    color: #6f6f6f;
    font-size: 16px;
    .share-img-box {
      width: 18px;
      height: 18px;
      img {
        display: block;
        width: 100%;
      }
    }
    .share-text {
      padding-left: 4px;
      font-size: 15px;
    }
  }
}
.good-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .original-price {
    text-decoration: line-through;
    font-size: 13px;
    color: #aaaaaa;
  }
}
.seckill-goods {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// .seckill-goods-title {
//   font-size: 17px;
//   font-weight: 500;
// }
.seckill-goods-name {
  font-size: 17px;
}
</style>
