<template>
  <!-- 分享弹出层 -->
  <div>
    <van-popup
      v-model="isShare"
      :close-on-click-overlay="false"
      @click-overlay="clickPopup"
      v-if="isShare"
    >
      <div class="share">
        <span class="close-btn" @click="clickPopup">
          <img src="~@/assets/common/cancel-button@2x.png" alt="" />
        </span>
        <div class="share-img-box">
          <img :src="imgUrl" />
        </div>
        <div class="btn-layout">
          <div class="btn-operation" @click="shareFriend">
            <div class="btn-img-box">
              <img
                src="~@/assets/common/share-wechat-distribution.png"
                alt=""
              />
            </div>
            <span>发送好友</span>
          </div>
          <div class="btn-operation" @click="preserve">
            <div class="btn-img-box">
              <img src="~@/assets/common/share-save-album.png" alt="" />
            </div>
            <span>保存相册</span>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="popup-tier" :class="{ none: isShow }">
      <div class="got-it" @click="understand">
        <img src="~@/assets/my/cancel_button@2x.png" alt="" />
      </div>
      <div class="hint">
        <img src="~@/assets/my/sharetips_img@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/common/js/tools.js";
import { Dialog } from "vant";
import { getGoodsShareInfo } from "@/api/goods.js";
import { getSeckillShareInfo } from "@/api/seckill";
import { getNewUserShareInfo } from "@/api/NewUser.js";
export default {
  name: "share",
  props: {
    goodsClass: {
      type: Object,
    },
    isShare: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgUrl: "",
      isShow: false,
      isWx: false,
    };
  },
  async mounted() {
    this.isWx = tools.isWx();
    if (this.isWx) {
      await tools.wxInitialize([
        "onMenuShareAppMessage",
        "updateAppMessageShareData",
        "updateTimelineShareData",
      ]);
    }
    if (this.goodsClass.goodsType === 1) {
      this.getGoodsShareInfo();
    } else if (this.goodsClass.goodsType === 5) {
      this.getNewUserShareInfo();
    } else {
      this.getSeckillShareInfo();
    }
  },
  methods: {
    understand() {
      this.isShow = false;
    },
    preserve() {
      if (this.isWx) {
        Dialog.alert({
          title: "提示",
          message: "长按图片保存到相册~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: "请到微信分享给你最爱的朋友吧~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      }
    },
    clickPopup() {
      this.$emit("closeShare");
    },
    shareFriend() {
      console.log(tools.isWx());
      if (tools.isWx()) {
        this.isShow = true;
      } else {
        Dialog.alert({
          title: "提示",
          message: "请到微信分享给你最爱的朋友吧~~",
          theme: "round-button",
        }).then(() => {
          // on close
        });
      }
    },
    async getNewUserShareInfo() {
      const res = await getNewUserShareInfo({
        newcomer_id: this.goodsClass.goodsId,
      });
      if (res.code === 1) {
        this.imgUrl = res.data.share_img;
        this.shareInfo = res.data;
        this.setWxShare();
      }
    },
    async getSeckillShareInfo() {
      const res = await getSeckillShareInfo({
        goods_id: this.goodsClass.goodsId,
        seckill_id: this.goodsClass.seckillId,
      });
      if (res.code * 1 === 1) {
        this.imgUrl = res.data.share_img;
        this.shareInfo = res.data;
        this.setWxShare();
      } else {
      }

      console.log(res);
    },
    async getGoodsShareInfo() {
      const res = await getGoodsShareInfo({
        goods_id: this.goodsClass.goodsId,
      });
      if (res.code * 1 === 1) {
        this.imgUrl = res.data.share_img;
        this.shareInfo = res.data;
        this.setWxShare();
      }
    },
    setWxShare() {
      let _that = this;
      wx.ready(function () {
        // 需在用户可能点击分享按钮前就先调用
        let myUrl = window.location.href;
        if (myUrl.indexOf("?") < 0) {
          myUrl = myUrl + "?";
        }
        if (myUrl.indexOf("m_id") !== -1) {
          myUrl = myUrl.substr(0, myUrl.indexOf("m_id"));
        }
        myUrl = myUrl + "&invite_code=" + _that.shareInfo.invite_code;
        wx.updateAppMessageShareData({
          title: _that.shareInfo.good_name, // 分享标题
          desc: "分享商品啦", // 分享描述
          link: myUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.cover_img, // 分享图标
          success: function () {
            // 设置成功
          },
        });
        wx.updateTimelineShareData({
          title: _that.shareInfo.good_name, // 分享标题
          link: myUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.shareInfo.cover_img, // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background-color: transparent;
  overflow: visible;
}
.van-popup--center {
  top: 55%;
}
.share {
  position: relative;
  width: 100%;
  height: 100%;
  .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -12px;
    right: -4px;
    img {
      display: block;
      width: 100%;
    }
  }
  .share-img-box {
    width: 336px;
    height: 508px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
}
.btn-layout {
  display: flex;
  justify-content: space-between;
  padding: 20px 58px;
  .btn-operation {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-img-box {
      width: 40px;
      height: 40px;
      img {
        display: block;
        width: 100%;
      }
    }
    span {
      margin-top: 6px;
      color: #fff;
    }
  }
}
.popup-tier {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  .got-it {
    position: absolute;
    bottom: 37%;
    right: 96px;
    width: 186px;
    img {
      display: block;
      width: 100%;
    }
  }
  .hint {
    position: absolute;
    right: 0;
    top: 0;
    width: 270px;
    img {
      display: block;
      width: 100%;
    }
  }
}
.none {
  display: block;
}
</style>
