<template>
  <div>
    <van-tabs
      v-model="tabNum"
      line-height="2"
      line-width="70"
      title-active-color="#ED301D"
      scrollspy
      sticky
      :border="isBorder"
    >
      <van-tab title="商品" title-class="tabs">
        <van-swipe class="my-swipe" indicator-color="white">
          <van-swipe-item
            :class="{ 'swipe-back': img.type * 1 === 2 }"
            v-for="(img, index) in swipeImg"
            :key="index"
            @touchmove="moveVideo"
          >
            <div class="swipe-img-box" v-if="img.type * 1 === 1">
              <img
                v-if="false"
                src="~@/assets/common/consumption-golabel.png"
                class="consume-gold"
              />
              <img :src="img.url" class="swipe-img" />
            </div>
            <div class="swipe-img-box" v-else>
              <img
                v-if="false"
                src="~@/assets/common/consumption-golabel.png"
                class="consume-gold"
              />
              <Player
                :url="img.url"
                :controls="true"
                :isPlay="true"
                setWidth="100%"
                :setHeight="setHeight"
                :autoplay="false"
                ref="videoServe"
              ></Player>
            </div>
          </van-swipe-item>
        </van-swipe>
        <!-- 新人专享 -->
        <NewUserDetail
          :goodsInfo="goodsInfo"
          v-if="goodsClass.goodsType === 5"
        ></NewUserDetail>
        <!-- 倒计时组件 -->
        <CountDown
          :goodsInfo="goodsInfo"
          :goodsClass="goodsClass"
          v-if="goodsClass.goodsType === 4"
          @setIsOpen="setIsOpen"
        ></CountDown>
        <!-- 商品基本信息 -->
        <GoodsInfo ref="gInfo" :goodsClass="goodsClass"></GoodsInfo>
        <div class="specification-card" @click="skuShowControl">
          <div class="sku-lable">
            <span class="sku-select">选择</span>
            <span class="sku-text">规格</span>
          </div>
          <van-icon name="arrow" size="18" color="#B9BABE" />
        </div>
      </van-tab>
      <van-tab
        title="评价"
        title-class="tabs"
        v-if="
          goodsClass.goodsType === 1 ||
          goodsClass.goodsType === 4 ||
          goodsClass.goodsType === 5
        "
      >
        <!-- 福利信息 -->
        <WelfareInfo
          v-if="goodsClass.goodsType !== 5"
          :welfare="welfare"
          :goodsInfo="goodsInfo"
          :goodsClass="goodsClass"
        ></WelfareInfo>
        <!-- 商品评价 -->
        <GoodsEvaluate
          :evaluateInfo="evaluateList"
          :goodsData="goodsClass"
        ></GoodsEvaluate>
      </van-tab>
      <van-tab title="详情" title-class="tabs">
        <!-- 商品详情 -->
        <GoodsDetail :info="goodsInfo"></GoodsDetail>
      </van-tab>
      <template #nav-left>
        <div class="icon-img-box icon-img-letf">
          <van-icon
            size="20"
            name="arrow-left"
            color="#6F6F6F"
            @click="$router.go(-1)"
          />
        </div>
      </template>
      <template #nav-right>
        <div class="icon-img-box icon-img-right">
          <!-- 根据类型判定是否显示购物车 -->
          <div class="cart-icon" v-if="goodsClass.goodsType === 1">
            <van-icon
              name="shopping-cart-o"
              size="20"
              color="#6F6F6F"
              @click="goToCart"
            />
            <div
              v-if="shoppingNum !== 0"
              :class="{
                'cart-icon-num1': 0 < shoppingNum * 1 < 9,
                'cart-icon-num2': shoppingNum * 1 > 9,
              }"
            >
              {{ shoppingNum }}
            </div>
          </div>
          <van-popover
            v-model="popoverShow"
            :actions="popoverData"
            placement="bottom-end"
            theme="dark"
            @select="onSelect"
          >
            <template #reference>
              <van-icon
                size="20"
                color="#6F6F6F"
                name="ellipsis"
                @touchend="ellipsis"
                class="icon-select"
              />
            </template>
          </van-popover>
        </div>
      </template>
    </van-tabs>
  </div>
</template>

<script>
import GoodsInfo from "./moudles/goods-info/goods-info";
import GoodsDetail from "./moudles/goods-detail/goods-detail";
import GoodsEvaluate from "./moudles/goods-evaluate/goods-evaluate";
import CountDown from "../components/count-down/count-down";
import WelfareInfo from "./moudles/welfare-info/welfare-info";
import NewUserDetail from "./moudles/new-user-detail/new-user-detail.vue";
import { getGoodsInfo, getCommentList } from "@/api/goods.js";
import { getSeckillInfo } from "@/api/seckill";
import { getBenefitInfo } from "@/api/benefit";
import { getGoodsDetail } from "@/api/NewUser.js";
import Player from "@/components/video/video";
import { Toast } from "vant";

export default {
  name: "detail",
  components: {
    GoodsInfo,
    CountDown,
    GoodsEvaluate,
    GoodsDetail,
    WelfareInfo,
    Player,
    NewUserDetail,
    // Sku,
  },
  data() {
    return {
      setHeight: "",
      isBorder: false,
      tabNum: 0,
      skuShow: false,
      shoppingNum: 0,
      swipeImg: [], //轮播
      goodsInfo: {}, //商品信息
      evaluateList: [], //评价信息
      goodsClass: {
        goodsId: this.$route.query.goodsId * 1 || 0,
        goodsType: this.$route.query.type * 1 || 0,
        seckillId: this.$route.query.seckillId * 1 || 0,
      },
      welfare: {},
      popoverShow: false,
      popoverData: [
        { text: "返回首页", icon: "wap-home-o" },
        { text: "购物车", icon: "cart-o" },
        { text: "分类", icon: "cluster-o" },
        { text: "我的", icon: "friends-o" },
      ],
    };
  },
  watch: {
    goodsInfo: function () {
      let is_collect = this.goodsInfo.is_collect * 1;
      this.$refs.gInfo.setGoodsInfo(this.goodsInfo);
      this.$emit("setCollect", is_collect);
    },
    swipeImg: function () {
      const _that = this;
      setTimeout(() => {
        console.log(document.querySelector(".swipe-img-box").offsetHeight);
        _that.setHeight = document.querySelector(".swipe-img-box").offsetHeight;
      }, 200);
    },
  },
  updated() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件
    console.log(this.goodsClass.goodsId);
    console.log(this.goodsClass.goodsType);
    if (this.goodsClass.goodsId <= 0 || this.goodsClass.goodsType <= 0) {
      this.$router.push("/");
      return;
    }
    if (this.goodsClass.seckillId > 0) {
      this.getSeckillInfo();
    } else if (this.goodsClass.goodsType === 3) {
      this.getBenefitInfo();
    } else if (this.goodsClass.goodsType === 5) {
      this.getGoodsDetail();
    } else {
      this.getGoodsInfo();
      this.getCommentList();
      this.getCartNum();
    }
  },
  methods: {
    moveVideo() {
      if (!this.$refs.videoServe) {
        if (this.$refs.videoServe && this.$refs.videoServe.length > 0) {
          this.$refs.videoServe[0].stopVideo();
        }
      }
    },
    setIsOpen(isOpen) {
      this.$emit("setIsOpen", isOpen);
    },
    async updateView(num) {
      //获取购物车数量
      this.shoppingNum = await this.$store.dispatch("getShoppingNum");
    },
    skuShowControl(bool, state, num) {
      // if (!bool) {
      //   this.skuShow = bool;
      //   if (state === 1) {
      //     console.log(num);
      //     this.shoppingNum = num;
      //   }
      //   return;
      // }
      // this.skuShow = true;
      this.$emit("showSku");
    },
    goToCart() {
      this.$router.push({ name: "shop-cart" });
    },
    addCartNum() {
      this.getCartNum();
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0) {
        this.isBorder = true;
      } else {
        this.isBorder = false;
      }
    },
    async getGoodsDetail() {
      // 新人专享
      const res = await getGoodsDetail({
        newcomer_id: this.goodsClass.goodsId,
      });
      if (res.code === 1) {
        if (res.data.new_member === 0) {
          Toast.fail("非新用户");
          this.$router.replace({ name: "home" });
          return;
        }
        this.goodsInfo = res.data;
        this.swipeImg = res.data.goods_imgs;
        this.$emit("setShopId", res.data.shop_id);
        this.$bus.$emit("getGoodsInfo", res.data);
      }
    },
    async getSeckillInfo() {
      // 获取商品详情 --- 秒杀
      const res = await getSeckillInfo({
        seckill_id: this.goodsClass.seckillId,
        goods_id: this.goodsClass.goodsId,
      });
      // console.log("秒杀", res);
      if (res.code * 1 === 1) {
        let welfares = res.data.goods_info.commission;
        if (welfares.member_comm * 1 > 0 || welfares.one_comm > 0) {
          this.welfare = res.data.goods_info.commission;
        }
        this.goodsInfo = res.data.goods_info;
        this.swipeImg = res.data.goods_info.goods_imgs;
        this.$emit("setShopId", res.data.goods_info.shop_id);
      }
    },
    async getGoodsInfo() {
      // 获取商品信息 --- 普通/消费金
      const res = await getGoodsInfo({ goods_id: this.goodsClass.goodsId });
      // console.log("普通-----------------", res);
      if (res.code * 1 === 1) {
        let welfares = res.data.goods_info.commission;
        if (welfares.member_comm * 1 > 0 || welfares.one_comm > 0) {
          this.welfare = res.data.goods_info.commission;
        }
        this.goodsInfo = res.data.goods_info;
        this.swipeImg = res.data.goods_info.goods_imgs;
        this.$emit("setShopId", res.data.goods_info.shop_id); // 自测完成（普通，进货）
      }
    },
    async getBenefitInfo() {
      // 获取公益豆
      let beanMoney = "";
      let beanPrice = "";
      let status = 0; // 0代表公益豆不足，1代表足
      const res = await getBenefitInfo({ benefit_id: this.goodsClass.goodsId });
      if (res.code * 1 === 1) {
        // console.log("公益豆-----------------", res);
        let welfares = res.data.goods_info.commission;
        if (welfares.member_comm * 1 > 0 || welfares.one_comm > 0) {
          this.welfare = res.data.goods_info.commission;
        }
        this.goodsInfo = res.data.goods_info;
        this.swipeImg = res.data.goods_info.goods_imgs;
        this.$emit("setShopId", res.data.shop_info.id);
        beanMoney = res.data.shop_info.bean_money;
        beanPrice = res.data.goods_info.price;
        if (beanPrice * 1 <= 0) {
          status = 1;
          return;
        } else {
          if (beanMoney < beanPrice || beanMoney === undefined) {
            status = 0;
          } else {
            status = 1;
          }
        }
        localStorage.setItem("IS_BUY", status);
      }
    },
    async getCommentList() {
      // 获取评论列表
      const res = await getCommentList({
        goods_id: this.goodsClass.goodsId,
        page_num: 3,
      });
      // console.log("评论-----------------", res);
      this.evaluateList = res.data;
    },
    async getCartNum() {
      // 获取购物车数量
      this.shoppingNum = await this.$store.dispatch("getShoppingNum");
      this.shoppingNum * 1 > 99 ? (this.shoppingNum = "99+") : this.shoppingNum;
    },
    onSelect(action, index) {
      // 快捷菜单
      if (index === 0) {
        this.$router.push("/");
      } else if (index === 1) {
        this.$router.push("/shop-cart");
      } else if (index === 2) {
        this.$router.push("/classify");
      } else if (index === 3) {
        this.$router.push("/my");
      }
    },
    ellipsis() {
      // 快捷菜单开关
      this.popoverShow = !this.popoverShow;
    },
  },
};
</script>

<style lang="less" scoped>
// tab
/deep/.tabs {
  font-size: 21px;
}
.icon-img-box {
  display: flex;
  align-items: center;
}
.icon-img-letf {
  padding-right: 28px;
}
.icon-img-right {
  padding-left: 28px;
}
/deep/.van-tab {
  color: #000;
}
/deep/.cart-icon .cart-icon-num1[data-v-726ec77e] {
  background: linear-gradient(180deg, #e89291 0%, #f02917 100%);
}
/deep/.van-tabs__nav--line {
  padding-left: 12px;
  padding-right: 12px;
}
/deep/.van-tabs__wrap {
  height: 57px;
}
.cart-icon {
  position: relative;
  padding-right: 17px;
  .cart-icon-num1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ee0a24;
    border: 1px solid #fff;
    color: #fff;
    font-size: 12px;
  }
  .cart-icon-num2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: 10px;
    width: 14px;
    height: 14px;
    padding: 0 5px;
    border-radius: 14px;
    background-color: #ee0a24;
    border: 1px solid #fff;
    color: #fff;
    font-size: 12px;
  }
}
/deep/.van-info {
  top: 4px;
}
//轮播
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #39a9ed;
}
.swipe-img-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 396px;
  .consume-gold {
    position: absolute;
    top: 0;
    left: 0;
    width: 52px;
    height: 24px;
  }
  .swipe-img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
// 规格
.sku-lable {
  font-size: 17px;
  .sku-select {
    font-weight: normal;
  }
  .sku-text {
    padding: 0 20px;
    color: #6f6f6f;
  }
}
.specification-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 12px 12px 12px;
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 16px;
}

.my-swipe .van-swipe-item {
  background: transparent;
}
.my-swipe .van-swipe-item {
  background-color: transparent;
}
.swipe-back {
  background-color: #000 !important;
}
</style>
