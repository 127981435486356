<template>
  <div class="particulars">
    <div class="particulars-title">-商品详情-</div>
    <div v-html="info.contents" class="detail-img"></div>
  </div>
</template>

<script>
export default {
  name: "goods-detail",
  props: {
    info: {
      default: {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
//详情
.particulars {
  padding-bottom: 70px;
  .particulars-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }
}
/deep/.detail-img {
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>