<template>
  <van-popup
    v-model="show"
    :close-on-click-overlay="false"
    @click-overlay="closePopup"
    position="bottom"
    round
  >
    <div class="discounts-popup">
      <div class="popup-title">
        <span>优惠券明细</span>
        <!-- <van-icon name="cross" class="close-icon" @click="closePopup" /> -->
      </div>
      <div class="discounts" id="reduced">
        <div class="discounts-box" v-if="noAlready.length > 0">
          <div class="label-text">可领取优惠券</div>
          <ul>
            <li
              class="discounts-list"
              v-for="(item, index) in noAlready"
              :key="index"
            >
              <div class="discounts-number" v-if="item.num > 0">
                {{ item.num }}张
              </div>
              <div class="list-left-box">
                <div class="list-left">
                  <div class="discounts-price">
                    <Price
                      :price="item.money"
                      integerStyle="26"
                      decimalStyle="13"
                    ></Price>
                  </div>
                  <div class="overflow-subtract">
                    {{
                      item.full_type === 1
                        ? `满${item.full_money}元可用`
                        : "无门槛"
                    }}
                  </div>
                </div>
              </div>
              <div class="list-right">
                <div class="describe-text">
                  {{ restrictLength(item.name) }}
                </div>
                <div class="time">{{ item.time_str }}</div>
                <div class="get-btn" @click="getDiscounts(item)">立即领取</div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 已领取 -->
        <div class="discounts-box" v-if="already.length > 0">
          <div class="label-text">已领取</div>
          <ul>
            <li
              class="discounts-list"
              v-for="(item, index) in already"
              :key="index"
              @click="getDiscountItem(item)"
            >
              <div class="discounts-number" v-if="item.num > 0">
                {{ item.num }}张
              </div>
              <div class="list-left-box">
                <div class="list-left">
                  <div class="discounts-price">
                    <Price
                      :price="item.money"
                      integerStyle="26"
                      decimalStyle="13"
                    ></Price>
                  </div>
                  <div class="overflow-subtract">
                    {{
                      item.full_type === 1
                        ? `满${item.full_money}元可用`
                        : "无门槛"
                    }}
                  </div>
                </div>
              </div>
              <div class="list-right">
                <div class="describe-text">
                  {{ restrictLength(item.name) }}
                </div>
                <div class="time">{{ item.time_str }}</div>
                <img
                  src="@/assets/discounts/expire.png"
                  class="expire"
                  v-if="item.is_receive === 1 && item.status !== 2"
                />
                <img
                  src="@/assets/discounts/pulldown_icon.png"
                  class="expire"
                  v-else-if="item.status === 2"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="load" v-if="loading || finished">
          {{ finished ? "没有更多优惠券了" : "加载中..." }}
        </div>
      </div>
      <!-- already.length <= 0 && noAlready.length <= 0 -->
      <div class="blank-box" v-if="showBlank">
        <div class="blank">
          <img src="@/assets/blank/icon@2x.png" class="blank-img" />
          <span class="blank-text">暂无优惠券</span>
        </div>
      </div>
      <div class="bottom-btn">
        <div class="close-btn" @click="closePopup">关闭</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getDetailDiscount, getDiscounts } from "@/api/discounts.js";
import { Toast } from "vant";
import { intercept } from "@/common/js/utils.js";
export default {
  props: {
    goodsId: {},
    goodsClass: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      already: [],
      noAlready: [],
      page: 1,
      showBlank: false,
    };
  },
  mounted() {
    this.initialData();
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          let reduced = document.querySelector("#reduced");
          reduced.addEventListener("scroll", this.onLoad);
        });
      }
    },
  },
  methods: {
    onLoad(e) {
      let ele = e.target;
      let scrollBottom = ele.scrollHeight - ele.scrollTop - ele.clientHeight;
      if (
        scrollBottom <= 0 &&
        this.finished === false &&
        this.loading === false
      ) {
        this.updateList();
      }
    },
    getDiscountItem(item) {
      this.$emit("getDiscount", item);
      this.show = false;
    },
    async getDiscounts(item) {
      const res = await getDiscounts({
        coupon_id: item.coupon_id,
        grant_id: item.grant_id,
        type: 1,
      });
      if (res.code === 1) {
        this.initialData();
        Toast.success(res.msg);
      }
    },
    updateList() {
      ++this.page;
      // this.getDetailDiscount();
    },
    initialData() {
      this.already = [];
      this.noAlready = [];
      this.page = 1;
      // this.getDetailDiscount();
    },
    async getDetailDiscount() {
      this.loading = true;
      let isSeckill = this.goodsClass.seckillId > 0 ? 1 : 0;
      const res = await getDetailDiscount({
        page: this.page,
        goods_id: this.goodsClass.goodsId,
        is_seckill: isSeckill,
      });
      if (res.code === 1) {
        this.loading = false;
        if (this.page === 1) {
          this.noAlready = res.data.can_receive;
          this.already = res.data.collar_list;
        } else {
          this.noAlready = res.data.can_receive;
          this.already = [...this.already, ...res.data.collar_list];
        }
        if (this.noAlready.length <= 0 && this.already.length <= 0) {
          this.showBlank = true;
        } else {
          this.showBlank = false;
        }
        if (res.data.collar_list.length <= 0) {
          this.finished = true;
          --this.page;
        } else {
          this.finished = false;
        }
        if (this.already.length > 0) {
          this.$emit("updatePrice", this.already[0]);
        } else if (this.noAlready.length > 0) {
          this.$emit("updatePrice", this.noAlready[0]);
        }
      }
      intercept(this.noAlready);
      intercept(this.already);
    },
    openPopup() {
      this.show = true;
    },
    closePopup() {
      this.$emit("getDiscount", this.already[0]);
      this.show = false;
    },
    restrictLength(str) {
      let s = "";
      if (str.length >= 13) {
        s = str.substring(0, 18);
        s = s + "...";
      } else {
        s = str;
      }
      return s;
    },
    firstFliter(str) {
      return str.split(" ")[0];
    },
    lastFliter(str) {
      return str.split(" ")[1];
    },
  },
};
</script>

<style lang="less" scoped>
.load {
  font-size: 13px;
  color: #999;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.discounts-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 586px;
  .blank-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .blank {
      display: flex;
      flex-direction: column;
      align-items: center;
      .blank-img {
        width: 221px;
        height: 144px;
      }
      .blank-text {
        text-align: center;
        margin-top: 13px;
        color: #aaaaaa;
        font-size: 17px;
      }
    }
  }
}
.popup-title {
  position: relative;
  height: 66px;
  font-size: 21px;
  padding: 0 12px;
  line-height: 66px;
  text-align: center;
  .close-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
}
.discounts {
  flex: 1;
  overflow: auto;
  padding: 0 12px;
  .discounts-box {
    &:last-child {
      margin-top: 23px;
    }
    .label-text {
      font-size: 16px;
      color: #999999;
    }
  }
  ul {
    margin-top: 14px;
    .discounts-list {
      position: relative;
      display: flex;
      background: url("../../../../assets/discounts/bg1@2x.png") no-repeat
        center / 100% 100%;
      margin-bottom: 10px;
      .discounts-number {
        position: absolute;
        top: 0;
        left: 0;
        width: 41px;
        height: 19px;
        text-align: center;
        background: linear-gradient(90deg, #ffd871 0%, #f69f1c 100%);
        border-radius: 3px;
        color: #fff;
        line-height: 19px;
      }
    }
  }
}
.list-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 118px;
  height: 106px;
  color: #ed301d;
  .discounts-price {
    display: flex;
    align-items: center;
    font-size: 16px;
    span {
      padding-top: 2px;
    }
    .price {
      font-size: 34px;
    }
  }
  .overflow-subtract {
    font-size: 13px;
    font-weight: 500;
  }
}
.list-right {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  height: 106px;
  padding: 14px;
  box-sizing: border-box;
  .describe-text {
    font-size: 17px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
  }
  .get-btn {
    position: absolute;
    right: 13px;
    bottom: 13px;
    width: 83px;
    height: 34px;
    background: rgb(245, 76, 64);
    border-radius: 34px;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
  }
  .expire {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 68px;
    height: auto;
  }
}

.time {
  display: flex;
  align-items: center;
  color: #999999;
  font-size: 13px;
  .text {
    margin: 0 4px;
  }
}
.bottom-btn {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 3px 12px;
  background: #fff;
  box-sizing: border-box;
  border-top: 1px solid #e4e4e4;
  .close-btn {
    height: 52px;
    background: linear-gradient(157deg, #fc5656 0%, #f40909 100%);
    box-shadow: 0px 2px 4px rgba(255, 0, 0, 0.16);
    border-radius: 52px;
    line-height: 52px;
    font-size: 21px;
    color: #fff;
    text-align: center;
  }
}
</style>
