<template>
  <div class="bg">
    <div class="left">
      <div class="price-box">
        <Price
          :price="goodsInfo.price"
          spanColor="#fff"
          integerStyle="24"
          decimalStyle="17"
        ></Price>
        <Price
          class="original-price"
          :price="goodsInfo.original_price"
          spanColor="#fff"
          integerStyle="16"
          decimalStyle="16"
        ></Price>
      </div>
      <div class="price-label">新人价</div>
    </div>
    <div class="right">月销{{ goodsInfo.sales }}+件</div>
  </div>
</template>

<script>
export default {
  props: {
    goodsInfo: {},
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.bg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 6px 25px;
  background: url("../../../../../assets/goods/bg@2x.png") no-repeat center /
    100% 100%;
}
.left {
  display: flex;
  .price-label {
    width: 62px;
    height: 26px;
    margin-left: 17px;
    font-size: 16px;
    background: rgb(255, 255, 255);
    border-radius: 26px;
    color: #eb034c;
    line-height: 26px;
    text-align: center;
  }
}
.original-price {
  font-size: 16px;
  text-decoration: line-through;
  color: #ffffff;
}
.right {
  font-size: 16px;
  color: #ffffff;
}
</style>