<template>
  <div>
    <div
      class="welfare-card"
      v-if="
        (welfare.member_comm > 0 || welfare.one_comm > 0) &&
        goodsClass.goodsType * 1 !== 4 && goodsInfo.is_expense*1!==1
      "
    >
      <span class="title-text">福利信息</span>
      <p class="welfare-one">分享商品朋友购买可获得返佣</p>
      <p class="welfare-two">购买商品获得返现</p>
    </div>
    <div class="welfare-card" v-else-if="goodsInfo.is_expense*1===1 && false">
      <span class="title-text">福利信息</span>
      <p class="welfare-one">购买消费金商品满{{row.initial_num}}元可使用{{row.initial_money}}元消费金</p>
      <p class="welfare-two">购买消费金商品可获得返现</p>
    </div>
  </div>
</template>

<script>
import {getDiscountsInfo} from "@/api/goods";

export default {
  name: "welfare-info",
  props: {
    welfare: {
      type: Object,
    },
    goodsClass: {
      type: Object,
    },
    goodsInfo: {
      type: Object,
    },
  },
  data() {
    return {
      row:{
        'initial_num':0, 'initial_money':0, 'increase_num':0, 'increase_money':0
      }
    };
  },
  async mounted() {
   let ret= await getDiscountsInfo({});
    if (ret.code === 1) {
      this.row = ret.data;
    }
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
}
.welfare-card {
  padding: 17px 15px;
  background-color: #fff;
  margin: 0 12px 12px 12px;
  border-radius: 13px;
}
.title-text {
  font-size: 17px;
  font-weight: 500;
}
.welfare-one {
  margin: 5px 0;
  font-size: 14px;
  color: #6f6f6f;
}
.welfare-two {
  font-size: 14px;
  color: #6f6f6f;
}
.price {
  color: #ed301d;
}
</style>
