<template>
  <!-- 秒杀倒计时 -->
  <div class="time-limit" v-if="isShow">
    <div class="price">
      <span class="present-price">
        <span>￥</span>
        <span>{{ toInteger(goodsInfo.price) || 0 }}</span>
        <span>{{ twoFloating(goodsInfo.price) || 0 }}</span>
      </span>
      <span class="original-price">
        <span>￥</span>
        <span>{{ toInteger(goodsInfo.original_price) || 0 }}</span>
        <span>{{ twoFloating(goodsInfo.original_price) || 0 }}</span>
      </span>
    </div>
    <div class="timer">
      <span>{{ showTime ? "今日开抢时间" : "距结束还剩" }}{{dateNum>0?(dateNum+'天'):''}}： </span>
    </div>
    <van-count-down
      :time="surplusTime"
      format="HH:mm:ss"
      @finish="onFinish"
      @change="onChange"
    >
      <template #default="timeData">
        <div class="countdown">
          <span class="block">{{ timeData.hours }}</span>
          <span class="colon">:</span>
          <span class="block">{{ timeData.minutes }}</span>
          <span class="colon">:</span>
          <span class="block">{{ timeData.seconds }}</span>
        </div>
      </template>
    </van-count-down>
  </div>
</template>

<script>
import tools from "@/common/js/tools";

export default {
  name: "count-down",
  props: {
    goodsInfo: {
      type: Object,
    },
  },
  data() {
    return {
      showTime: false,
      isShow: false,
      newTime: "",
      dateNum: 0,
    };
  },
  mounted() {
    this.newTime = parseInt(Date.now() / 1000);


  },
  watch: {
    goodsInfo(newVal, oldVal) {
      if (Object.keys(newVal).length) {
        this.isShow = true;
      }
      if (this.goodsInfo.start_hour > this.newTime) {
        this.showTime = true;
        this.$emit('setIsOpen', true)
        let _that = this;
        let timeServe = setInterval(function () {
          ++_that.newTime;
          if (_that.newTime >= _that.goodsInfo.start_hour) {
            _that.showTime = false;
            clearInterval(timeServe);
            _that.$emit('setIsOpen', false)
          }
        }, 1000)
      }
      let dateNum = 0;
      let time = 0;
      if (this.showTime) {
        time = (this.goodsInfo.start_hour - this.newTime) * 1000;
        dateNum=parseInt(time/86400000);
      } else {
        time = (this.goodsInfo.end_hour - this.newTime) * 1000;
        dateNum=parseInt(time/86400000);
      }
      this.dateNum=dateNum;

    },
  },
  computed: {
    surplusTime() {
      let time = 0;
      if (this.showTime) {
        time = (this.goodsInfo.start_hour - this.newTime) * 1000;
        time=time%86400000;
      } else {
        time = (this.goodsInfo.end_hour - this.newTime) * 1000;
        time=time%86400000;
      }
      // this.dateNum=dateNum;

      return time;
    },
  },
  methods: {
    onFinish() {
      this.$store.commit("setTime", true);
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = Math.floor(num * 100) / 100;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      // return price;
    },
    onChange(timeData) {
    },
  },
};
</script>

<style lang="less" scoped>
// 秒杀时间倒计时
.time-limit {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  padding: 6px 18px 6px 12px;
  background: url("~@/assets/seckill/Seconds_kill_background_3@2x.png") no-repeat center / 100% 100%;

  .present-price {
    color: #fff;
    font-size: 18px;
    padding-right: 10px;
  }

  .original-price {
    color: #fff;
    font-size: 14px;
    text-decoration: line-through;
  }

  .timer {
    position: absolute;
    top: 8px;
    right: 28px;
    font-size: 14px;
    color: #fc2712;
    font-weight: 600;
  }
}

.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}

.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #ee0a24;
  border-radius: 6px;
}
</style>
